import React from 'react';

import Layout from '../components/Layout';
import {Helmet} from "react-helmet";

export const CovidPageTemplate = () => (
  <main role="main" className="covid-page">
    <Helmet>
      <title>Covid-19</title>
    </Helmet>
    <div>
      {/* Begin Banner */}
      <div className="jumbotron covid-19 mb-0">
        <div className="container text-light">
          <h1 className="display-5 text-white" style={{position: 'relative', zIndex: 1}}>Pete Health:<br /><span style={{fontWeight: 400}}>Here For Your Safety</span> </h1>
          <img className="covid-19__img" src="/assets/newimg/covid-banner.png" alt="" />
        </div>
      </div>{/* End Banner */}
      <div className="covid__wrapper">
        <div className="covid__content">
          <img className="covid__img" src="/assets/img/covid-content-img.png" alt="" />
          <div className="covid__text">
            <p>
              While avoiding public places like hospitals and clinics is paramount to preventing the spread of COVID-19, it remains critically important that patients continue to receive necessary care. <b>PeteHealth offers the perfect way to recover and continue receiving the essential service of physical therapy with the choice of in-person treatment in your home, or via teletherapy.</b>
            </p>
            <p>
              At PeteHealth, patients' health and safety remain our top priority.  Because of COVID-19, we’ve assessed and enhanced PeteHealth’s safety protocols. We want to ensure all patients and therapists continue to have access to safe and convenient physical therapy, without having to visit a clinic. Along with following CDC health guidelines, our therapists also use infection controlled materials.
            </p>
            <p>
              PeteHealth therapists have always followed best practices in infection control and will continue to build upon that foundation during this time of increased risk of infection and transmission. Our therapists understand the necessity of strict adherence to these infection control practices with respect to COVID-19. PeteHealth therapists will also deploy daily self-screening precautions and alter any necessary schedule changes that result. This also includes screening each patient at the beginning of every session by asking if they have been exposed to someone or are personally experiencing COVID-19 symptoms.
            </p>
            <p>
              PeteHealth therapists will continue to use sanitizing procedures before and after each patient appointment to reduce potential viral spread. By assessing and enhancing protocols already established, patients and therapists can confidently receive and provide safe care.
            </p>
            <p>
              PeteHealth is also happy to offer teletherapy via the video platform of your choice. Our concierge team is here to set you up with an evaluation performed by one of our highly skilled therapists via in-home treatment or telehealth. PeteHealth is fully covered by Medicare, and major insurance companies. We also offer out-of-pocket treatment at a flat fee of $175. We are here to schedule your treatment and assist with questions or concerns in regards to treatment protocols. <b>Contact us directly at: 888-859-0145.</b>
            </p>
          </div>
          <div className="covid__btn">
            <a className="btn btn-gr  schedule" data-toggle="modal" data-target="#FormModalCenter" role="button">Schedule visit</a>
          </div>
        </div>
      </div>
    </div>
  </main>
);

const CovidPage = () => {
  return (
    <Layout>
      <CovidPageTemplate />
    </Layout>
  )
};


export default CovidPage;
